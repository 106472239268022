import React, { useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import NavBar from "../../components/navBar";
import { useSelector } from "react-redux";
import Login from "../Authentication/Login";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  return token ? (
    <div style={styles.container}>
      <NavBar />
      <div style={styles.content}>
        {/* Add your main dashboard content here */}
      </div>
    </div>
  ):<Navigate to="/" />;
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#ffffff",
  },
  content: {
    flex: 1,
    padding: "20px",
    overflowY: "auto",
  },
};

export default Dashboard;
