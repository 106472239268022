import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card, Descriptions, List, Spin, Alert, Button, Table } from 'antd';
import { shipmentDetailRequest } from '../../features/shipment/shipmentActions';
import NavBar from '../../components/navBar';
import { ALLOCATION_PRIORITY_MAP } from '../../constants/shipheroConstants';

const { Item } = Descriptions;

const ShipmentDetail = () => {

    const { shipmentId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shipmentDetail = useSelector(state => state.shipment.shipmentDetail);
    const loading = useSelector(state => state.shipment.loading);
    const error = useSelector(state => state.shipment.error);
    useEffect(() => {
        dispatch(shipmentDetailRequest(shipmentId));
    }, [dispatch, shipmentId]);

    const handleBackClick = () => {
        navigate('/usr/shipmentList');
    };

    if (loading) {
        return <Spin tip="Loading..." />;
    }
    if (error) {
        return <Alert message="Error" description={error} type="error" showIcon />
    }
    if (!shipmentDetail) {
        return <>
            <Alert message="No shipment details available" type="warning" showIcon />
        </>
    }

    const columnsLineItems = [
        { title: 'Order Line Item ID', dataIndex: 'orderLineItemId', key: 'orderLineItemId' },
        { title: 'Shipment Line Item ID', dataIndex: 'shipmentLineItemId', key: 'shipmentLineItemId' },
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
        { title: 'Customs Value', dataIndex: 'customsValue', key: 'customsValue' },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' }
    ];

    const columnsShippingLabels = [
        // { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Created Date', dataIndex: 'created_date', key: 'created_date' },
        { title: 'Profile', dataIndex: 'profile', key: 'profile' },
        {
            title: 'Tracking Number',
            dataIndex: 'trackingNumber',
            key: 'trackingNumber',
            render: (text, record) => (
                <a href={record.trackingUrl} target="_blank" rel="noopener noreferrer">{record.trackingNumber}</a>
            )
        },
        // {
        //     title: 'Tracking URL',
        //     dataIndex: 'trackingUrl',
        //     key: 'trackingUrl',
        //     render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">Track</a>
        // },
        { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
        { title: 'Shipping Name', dataIndex: 'shippingName', key: 'shippingName' },
        { title: 'Shipping Method', dataIndex: 'shippingMethod', key: 'shippingMethod' },
        // { title: 'Warehouse ID', dataIndex: 'warehouseId', key: 'warehouseId' },
        { title: 'Warehouse', dataIndex: 'warehouse', key: 'warehouse' },
    ];

    return (
        <div>
            <NavBar />

            <div style={{ padding: '20px' }}>
                <Card title="Shipment Detail" bordered={false}>
                    <Descriptions bordered>
                        <Descriptions.Item label="Customer Name">{shipmentDetail.customerName}</Descriptions.Item>
                        <Descriptions.Item label="Order Number">{shipmentDetail.orderNumber}</Descriptions.Item>
                        <Descriptions.Item label="Order Date">{shipmentDetail.orderDate}</Descriptions.Item>
                        <Descriptions.Item label="Ship Date">{shipmentDetail.shipDate}</Descriptions.Item>
                        <Descriptions.Item label="Profile">{shipmentDetail.profile}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card title="Receiver Address" bordered={false} style={{ marginTop: '16px' }}>
                    <Descriptions bordered>
                        <Descriptions.Item label="Name">{shipmentDetail.receiverAddress.name}</Descriptions.Item>
                        {/* <Descriptions.Item label="Phone">{shipmentDetail.receiverAddress.phone}</Descriptions.Item> */}
                        <Descriptions.Item label="Address">
                            {shipmentDetail.receiverAddress.address1}
                            {shipmentDetail.receiverAddress.address2 && (
                                <>
                                    ,<br />
                                    {shipmentDetail.receiverAddress.address2}
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Zip">{shipmentDetail.receiverAddress.zip}</Descriptions.Item>
                        <Descriptions.Item label="City">{shipmentDetail.receiverAddress.city}</Descriptions.Item>
                        <Descriptions.Item label="State">{shipmentDetail.receiverAddress.state}</Descriptions.Item>
                        <Descriptions.Item label="Country">{shipmentDetail.receiverAddress.country}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card title="Dimensions" bordered={false} style={{ marginTop: '16px' }}>
                    <Descriptions bordered>
                        <Descriptions.Item label="Weight">{shipmentDetail.dimensions.weight}</Descriptions.Item>
                        <Descriptions.Item label="Length">{shipmentDetail.dimensions.length}</Descriptions.Item>
                        <Descriptions.Item label="Width">{shipmentDetail.dimensions.width}</Descriptions.Item>
                        <Descriptions.Item label="Height">{shipmentDetail.dimensions.height}</Descriptions.Item>
                        <Descriptions.Item label="Billable Weight">{ }</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card title="Line Items" bordered={false} style={{ marginTop: '16px' }}>
                    <Table
                        columns={columnsLineItems}
                        dataSource={shipmentDetail.lineitems}
                        pagination={false}
                        rowKey="orderLineItemId"
                    />
                </Card>

                <Card title="Shipping Labels" bordered={false} style={{ marginTop: '16px' }}>
                    <Table
                        columns={columnsShippingLabels}
                        dataSource={shipmentDetail.shippingLabels}
                        pagination={false}
                        rowKey="id"
                    />
                </Card>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <div>
                        <Button type="primary" onClick={handleBackClick}>Back to list</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default ShipmentDetail;
