import axios from "axios";
import { getToken } from "./utils/auth";

// src/api.js

// Create an axios instance with default configuration
export const apiClient = axios.create({
  baseURL:
    process.env.REACT_APP_DEV_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": getToken(),
  },
});

// Define your API calls
export const signIn = (payload) => {
  apiClient.post('/auth/signin/', payload)
};
