// src/app/rootSaga.js
import { all } from 'redux-saga/effects';
import watchSignin from '../features/auth/authSaga';
import rateInquirySaga from '../features/rateInquiry/rateInquirySaga';
// import other sagas here
import orderSaga from '../features/order/orderSaga';
import shipmentSaga from '../features/shipment/shipmentSaga';
import ubiManifestSaga from '../features/ubiManifest/ubiManifestSaga';
import metadataSaga from '../features/metadata/metadataSaga';
import warehouseSaga from '../features/warehouse/warehouseSaga';
import warehouseProductSaga from '../features/warehouseProduct/warehouseProductSaga';
import clientSaga from '../features/client/clientSaga';

export default function* rootSaga() {
  yield all([
    watchSignin(),
    rateInquirySaga(),
    // other sagas
    orderSaga(),
    shipmentSaga(),
    metadataSaga(),
    warehouseSaga(),
    warehouseProductSaga(),
    clientSaga(),
    ubiManifestSaga()
  ]);
}
