import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {},
    shipmentList: {
        data: [],
        pageIndex: 1,
        pageSize: 10,
        total: 0
    },
    shipmentDetail: null,
    loading: false,
    error: null,
    token: null,
};

const shipmentSlice = createSlice({
    name: "shipment",
    initialState,
    reducers: {
        shipmentListRequest: (state, action) => {
            state.loading = true;
            state.error = null;
            state.filter = action.payload;
        },
        shipmentListSuccess: (state, action) => {
            state.loading = false;
            state.shipmentList = action.payload;
        },
        shipmentListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /***********Shipment Detail************/
        shipmentDetailRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        shipmentDetailSuccess: (state, action) => {
            state.loading = false;
            state.shipmentDetail = action.payload;
        },
        shipmentDetailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    shipmentListRequest,
    shipmentListSuccess,
    shipmentListFailure,
    shipmentDetailRequest,
    shipmentDetailSuccess,
    shipmentDetailFailure
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
