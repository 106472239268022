import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import TooltipContent from "./toolTipContent";

const ResultTable = ({ result }) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={styles.tableHead}>
          <TableRow>
            {[
              "Country",
              "Residential",
              "Zip Code",
              "Length",
              "Width",
              "Height",
              "Weight",
              "USPS GA UBI",
              "USPS GA ES",
              "USPS GA IB",
              "USPS GA IB Cubic",
              "UPS Ground",
              "UPS Ground ES",
              "UPS Ground Saver ES",
              "UPS 2nd Day",
              "UPS Next Day",
            ].map((header) => (
              <TableCell key={header} style={styles.tableHeadCell}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((item, index) => (
            <StyledTableRow key={index}>
              <TableCell>{"US"}</TableCell>
              <TableCell>
                {item.input.residential === true ? (
                  <Tooltip title="Residential Address">
                    <CheckIcon style={{ color: "green", cursor: "pointer" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Residential Address">
                    <CloseIcon style={{ color: "red", cursor: "pointer" }} />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>{item.input.zipCode}</TableCell>
              <TableCell>
                {item.input.length}
                {result.dimensionUnit}
              </TableCell>
              <TableCell>
                {item.input.width}
                {result.dimensionUnit}
              </TableCell>
              <TableCell>
                {item.input.height}
                {result.dimensionUnit}
              </TableCell>
              <TableCell>
                {item.input.weight}
                {result.weightUnit}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "US.USPS.GA.DOMESTIC"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "US.USPS.GA.DOMESTIC"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "US.USPS.GA.DOMESTIC"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "US.USPS.GA.DOMESTIC"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "Easyship USPS GA"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "Easyship USPS GA"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "Easyship USPS GA"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "Easyship USPS GA"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "IB USPS Actual Weight"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "IB USPS Actual Weight"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "IB USPS Actual Weight"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "IB USPS Actual Weight"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "IB USPS Actual or Cubic Weight"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) =>
                          res.channelCode === "IB USPS Actual or Cubic Weight"
                      ),
                      item.result.find(
                        (res) => res.channelCode === "IB USPS Actual Weight"
                      ).totalAmount ===
                        item.result.find(
                          (res) =>
                            res.channelCode === "IB USPS Actual or Cubic Weight"
                        ).totalAmount
                        ? "Non-Cubic Price"
                        : ""
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) =>
                          res.channelCode === "IB USPS Actual or Cubic Weight"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) =>
                            res.channelCode === "IB USPS Actual or Cubic Weight"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "UBI.US.DOMESTIC.UPS GROUND"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) =>
                          res.channelCode === "UBI.US.DOMESTIC.UPS GROUND"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) =>
                          res.channelCode === "UBI.US.DOMESTIC.UPS GROUND"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) =>
                            res.channelCode === "UBI.US.DOMESTIC.UPS GROUND"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "Easyship UPS Ground"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "Easyship UPS Ground"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "Easyship UPS Ground"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "Easyship UPS Ground"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "Easyship UPS Ground Saver"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "Easyship UPS Ground Saver"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "Easyship UPS Ground Saver"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) =>
                            res.channelCode === "Easyship UPS Ground Saver"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "UBI.US.DOMESTIC.UPS 2D"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "UBI.US.DOMESTIC.UPS 2D"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "UBI.US.DOMESTIC.UPS 2D"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "UBI.US.DOMESTIC.UPS 2D"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
              <TableCell>
                {item.result.find(
                  (res) => res.channelCode === "UBI.US.DOMESTIC.UPS ND"
                ) ? (
                  <Tooltip
                    style={{ cursor: "pointer" }}
                    title={TooltipContent(
                      item.result.find(
                        (res) => res.channelCode === "UBI.US.DOMESTIC.UPS ND"
                      )
                    )}
                  >
                    <span>
                      {item.result.find(
                        (res) => res.channelCode === "UBI.US.DOMESTIC.UPS ND"
                      ).dataList[0].currency === "USD"
                        ? "$"
                        : ""}
                      {
                        item.result.find(
                          (res) => res.channelCode === "UBI.US.DOMESTIC.UPS ND"
                        ).totalAmount
                      }
                    </span>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
  },
  header: {
    marginBottom: "20px",
    padding: "10px 0",
  },
  title: {
    fontWeight: "bold",
  },
  unitsContainer: {
    display: "flex",
    gap: "10px",
  },
  unitCard: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#e3f2fd",
    minWidth: "180px",
  },
  unitCardContent: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
  },
  unitIcon: {
    marginRight: "8px",
  },
  tableHead: {
    backgroundColor: "#1976d2",
  },
  tableHeadCell: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  addButton: {
    marginRight: "10px",
  },
};

export default ResultTable;
