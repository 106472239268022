// src/components/Login.js
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { signinRequest } from "../../features/auth/authActions";
import logoImage from "../../assets/EE-Logo-Transparent.png";
import { LoadingOutlined } from "@ant-design/icons";
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user, error, loading } = useSelector((state) => state.auth);

  // Create a ref for the email field
  const emailRef = useRef(null);

  // Focus the email field when the component mounts
  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const initialValues = { email: "", password: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("email is required"),
    password: Yup.string().required("password is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(signinRequest(values));
    setSubmitting(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token || user) {
      navigate("/usr/dashboard"); // Navigate after login is successful
    }
  }, [user, navigate]);

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo">
          <img src={logoImage} alt="Logo" />
        </div>
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  innerRef={emailRef} // Attach the ref to the email field
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field type="password" id="password" name="password" />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>

              <button className="button" type="submit" disabled={isSubmitting || loading}>
                {isSubmitting || loading ? <LoadingOutlined /> : "Login"}
              </button>
            </Form>
          )}
        </Formik>
        <div className="login-footer">
          <a href="#">Forgot Password?</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
