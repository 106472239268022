// src/Pages/OrderList/orderList.js
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { orderListRequest } from '../../features/order/orderActions';
import { shippingMethodListRequest, fetchCustomerList, fetchWarehouseList } from '../../features/metadata/metadataActions';
import { Table, Button, Input, Select, DatePicker, Row, Col } from 'antd';
import NavBar from '../../components/navBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const { Option } = Select;
const { RangePicker } = DatePicker;

const OrderList = () => {
  useDocumentTitle('Order List - ODIN');
  const dispatch = useDispatch();
  const { orderList, loading } = useSelector((state) => state.order);
  const { shippingMethodList, carrierList, customerList, warehouseList } = useSelector((state) => state.metadata);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [filteredShippingMethods, setFilteredShippingMethods] = useState([]);

  const [filters, setFilters] = useState({
    orderNumber: '',
    clientId: '',
    clientName: '',
    warehouseIdentifier: '',
    orderDateStart: null,
    orderDateEnd: null,
    orderStatus: '',
    country: '',
    zip: '',
    state: '',
    address: '',
    receiverName: '',
    carrier: '',
    shippingMethod: '',
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    //Init Query
    //Metadata query
    dispatch(fetchCustomerList());
    dispatch(shippingMethodListRequest());
    dispatch(fetchWarehouseList());
    //First page query
    dispatch(orderListRequest({}));
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleOrderDateChange = (dateArray, dateStringArray) => {
    const [start, end] = dateStringArray;
    setFilters({ ...filters, orderDateStart: start, orderDateEnd: end });
  }

  const handleSelectChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    dispatch(orderListRequest(filters));
  };

  const handleCarrierChange = (selectedCarrier) => {
    setSelectedCarrier(selectedCarrier);
    setFilters({ ...filters, "carrier": selectedCarrier, "shippingMethod": '' });
    const filteredMethods = shippingMethodList.filter(method => method.carrier === selectedCarrier);
    setFilteredShippingMethods(filteredMethods);
  }


  const columns = [
    { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
    {
      title: 'Order Number',
      key: 'orderNumber',
      render: (text, record) => (
        <Link to={`/usr/orderDetail/${record.orderId}`}>{record.orderNumber}</Link>
      ),
    },
    { title: 'Origin Warehouse', dataIndex: 'warehouseName', key: 'warehouseName' },
    { title: 'Order Date', dataIndex: 'orderDate', key: 'orderDate' },
    { title: 'Order Status', dataIndex: 'orderStatus', key: 'orderStatus' },

    { title: 'Country', dataIndex: 'country', key: 'country' },
    { title: 'Zip', dataIndex: 'zip', key: 'zip' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    {
      title: 'Address',
      key: 'address',
      render: (text, record) => (
        <>
          <span>{record.address1}</span>
          {record.address2 && <><br /><span>{record.address2}</span></>}
        </>
      ),
    },
    { title: 'Receiver Name', dataIndex: 'receiverName', key: 'receiverName' },

    { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
    { title: 'Shipping Method', dataIndex: 'shippingMethod', key: 'shippingMethod' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <a href={`https://app.shiphero.com/dashboard/orders/details/${record.orderId}`} target="_blank" rel="noopener noreferrer">Open in SH</a>
      )
    },
  ];

  return (
    <div>
      <NavBar />
      <div style={{ padding: 20 }}>
        <Row gutter={16}>
          <Col span={4}>
            <Select
              allowClear
              showSearch
              placeholder="Client Name"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => handleSelectChange('clientId', value)}
              style={{ width: '100%' }}
            >
              {customerList.map(customerItem => (
                <Option key={customerItem.id} value={customerItem.id}>{customerItem.name}</Option>
              ))}
            </Select>

          </Col>
          <Col span={4}>
            <Select
              placeholder="Warehouse"
              onChange={(value) => handleSelectChange('warehouseIdentifier', value)}
              style={{ width: '100%' }}
              allowClear
            >
              {warehouseList.map(warehouseItem => (
                <Option key={warehouseItem.id} value={warehouseItem.identifier}>{warehouseItem.displayName}</Option>
              ))}
            </Select>
          </Col>

          <Col span={3}>
            <Input placeholder="Order Number" name="orderNumber" value={filters.orderNumber} onChange={handleInputChange} />
          </Col>

          <Col span={3}>
            <Select
              placeholder="Order Status"
              //value={filters.orderStatus}
              onChange={(value) => handleSelectChange('orderStatus', value)}
              style={{ width: '100%' }}
              allowClear
            >
              <Option value="fulfilled">Fulfilled</Option>
              <Option value="unFulfilled">Unfulfilled</Option>
            </Select>
          </Col>

          {/* TODO Arthur:Has problem,needs to refactor first */}
          {/* <Col span={6}>
            <RangePicker
              placeholder={['Order Date From', 'End (Till Now)']}
              allowEmpty={[false, true]}
              onChange={(date, dateString) => handleOrderDateChange(date, dateString)}
            />
          </Col> */}

        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={3}>
            <Input placeholder="Country" name="country" value={filters.country} onChange={handleInputChange} />
          </Col>
          <Col span={3}>
            <Input placeholder="Zip" name="zip" value={filters.zip} onChange={handleInputChange} />
          </Col>
          <Col span={3}>
            <Input placeholder="State" name="state" value={filters.state} onChange={handleInputChange} />
          </Col>
          <Col span={4}>
            <Input placeholder="Address" name="address" value={filters.address} onChange={handleInputChange} />
          </Col>
          <Col span={4}>
            <Input placeholder="Receiver Name" name="receiverName" value={filters.receiverName} onChange={handleInputChange} />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={4}>
            <Select
              placeholder="Carrier Type"
              onChange={(value) => handleCarrierChange(value)}
              style={{ width: '100%' }}
              allowClear
            >
              {carrierList.map(carrierItem => (
                <Option key={carrierItem} value={carrierItem}>{carrierItem}</Option>
              ))}
            </Select>
          </Col>

          <Col span={5}>
            <Select
              placeholder="Shipping Method"
              value={filters.shippingMethod}
              onChange={(value) => handleSelectChange('shippingMethod', value)}
              disabled={!selectedCarrier}
              style={{ width: '100%' }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {
                filteredShippingMethods.map(methodItem => (
                  <Option key={methodItem.method} value={methodItem.method}>{methodItem.method}</Option>
                ))
              }
            </Select>
          </Col>

          <Col span={2}>
            <Button type="primary" onClick={handleSearch}>Search</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={orderList.data}
          loading={loading}
          bordered
          rowKey="orderId"
          pagination={{
            current: orderList.pageIndex,
            pageSize: orderList.pageSize,
            total: orderList.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: (pageIndex, pageSize) => {
              dispatch(orderListRequest({ ...filters, pageIndex, pageSize }));
            },
          }}
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default OrderList;
