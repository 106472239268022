// src/features/auth/authSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { signinRequest, signinSuccess, signinError } from "./authSlice";
import { notify } from "../../utils/notifications";
import { apiClient } from "../../api";

function* signin(action) {
  try {
    const response = yield call(
      apiClient.post,
      "/auth/signin/",
      action.payload
    );
    const token = response.data.access_token;
    if (token) {
      localStorage.setItem("token", token);
      yield put(signinSuccess(response.data));
      window.location.href = '/usr/dashboard';  // navigate to dashboard
      notify("success", "Log in successful", 2);
    } else {
      yield put(signinError("Invalid credentials"));
      notify("error", "Invalid credentials", 2);
    }
  } catch (error) {
    yield put(signinError(error.message));
    notify("error", error.message, 2);
  }
}

function* watchSignin() {
  yield takeLatest(signinRequest.type, signin);
}

export default watchSignin;
