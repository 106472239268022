import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
  shipmentListRequest,
  shipmentListSuccess,
  shipmentListFailure,
  shipmentDetailRequest,
  shipmentDetailSuccess,
  shipmentDetailFailure,
} from "./shipmentSlice";

function* queryShipment(action) {
  try {
    //Convert date to string if user inputed.
    let requestBody = { ...action.payload };
    if (requestBody.orderDateStart)
      requestBody.orderDateStart =
        requestBody.orderDateStart.format("YYYY-MM-DD");
    if (requestBody.orderDateEnd)
      requestBody.orderDateEnd = requestBody.orderDateEnd.format("YYYY-MM-DD");
    if (requestBody.shipDateStart)
      requestBody.shipDateStart =
        requestBody.shipDateStart.format("YYYY-MM-DD");
    if (requestBody.shipDateEnd)
      requestBody.shipDateEnd = requestBody.shipDateEnd.format("YYYY-MM-DD");
    if (requestBody && requestBody.pageIndex >= 1) {
      requestBody.pageIndex -= 1;
    }

    const response = yield call(apiClient.post, "/shipment/list", requestBody);
    const responseBody = response.data;
    const { status, message, result } = responseBody;
    if (status === "fail") throw new Error(message);
    result.pageIndex += 1;
    yield put(shipmentListSuccess(result));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(shipmentListFailure(error.message));
  }
}

function* fetchShipmentDetail(action) {
  try {
    const response = yield call(
      apiClient.get,
      `/shipment/detail/${action.payload}`
    );
    const responseBody = response.data;
    const { status, message, result } = responseBody;
    if (status === "fail") throw new Error(message);
    yield put(shipmentDetailSuccess(result));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(shipmentDetailFailure(error.message));
  }
}

function* watchQueryShipment() {
  yield takeLatest(shipmentListRequest.type, queryShipment);
  yield takeLatest(shipmentDetailRequest.type, fetchShipmentDetail);
}

export default watchQueryShipment;
