import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Button,
} from "@mui/material";
import ScaleIcon from "@mui/icons-material/Scale";
import StraightenIcon from "@mui/icons-material/Straighten";
import NavBar from "../../components/navBar";

import { resetRows } from "../../features/rateInquiry/rateInquiryActions";
import ResultTable from "./resultTable";

const Results = () => {
  const { loading, result } = useSelector((state) => state.rateInquiry);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditQuote = () => {
    navigate(-1);
  };

  const handleNewQuote = () => {
    dispatch(resetRows());
    navigate(-1);
  };

  return (
    <div>
      <NavBar />
      {loading ? (
        <div style={styles.loadingContainer}>
          <CircularProgress size={60} />
        </div>
      ) : (
        <div style={styles.container}>
          <Grid container justifyContent="space-between" style={styles.header}>
            <Typography variant="h4" style={styles.title}>
              Results
            </Typography>
            <div style={styles.unitsContainer}>
              <Card style={styles.unitCard}>
                <CardContent style={styles.unitCardContent}>
                  <ScaleIcon style={styles.unitIcon} />
                  <Typography variant="body1">
                    Weight Unit: {result.weightUnit}
                  </Typography>
                </CardContent>
              </Card>
              <Card style={styles.unitCard}>
                <CardContent style={styles.unitCardContent}>
                  <StraightenIcon style={styles.unitIcon} />
                  <Typography variant="body1">
                    Dimension Unit: {result.dimensionUnit}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
          <ResultTable result={result} />
          <Grid container justifyContent="right" style={styles.header}>
            <div style={styles.unitsContainer}>
              <Button
                title="Edit Quote"
                variant="contained"
                size="medium"
                style={styles.button}
                onClick={handleEditQuote}
              >
                Edit Quote
              </Button>
              <Button
                title="New Quote"
                variant="contained"
                size="medium"
                style={styles.button}
                onClick={handleNewQuote}
              >
                New Quote
              </Button>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
  },
  header: {
    marginBottom: "20px",
    padding: "10px 0",
  },
  title: {
    fontWeight: "bold",
  },
  unitsContainer: {
    display: "flex",
    gap: "10px",
  },
  unitCard: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#e3f2fd",
    minWidth: "180px",
  },
  unitCardContent: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
  },
  unitIcon: {
    marginRight: "8px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  button: {
    padding: "10px",
    backgroundColor: "#e3f2fd",
    minWidth: "120px",
    color: "black",
  },
};

export default Results;
