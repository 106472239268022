import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import Login from "./Pages/Authentication/Login.js";
import USRateInquiry from "./Pages/rateInquiry/usRateInquiry.js";
import USRateInquiryResult from './Pages/rateInquiry/rateInquiryResult.js';
import OrderList from './Pages/OrderList/orderList';
import OrderDetail from './Pages/OrderDetail/orderDetail';
import ShipmentList from './Pages/ShipmentList/shipmentList';
import ShipmentDetail from './Pages/ShipmentDetail/shipmentDetail';
import WarehouseList from "./Pages/WarehouseList/warehouseList.js";
import WarehouseProductList from './Pages/WarehouseProduct/warehouseProductList.js';
import WarehouseProductDetail from './Pages/WarehouseProduct/warehouseProductDetail.js';
import ClientList from './Pages/Client/clientList.js';
import ClientDetail from './Pages/Client/clientDetail.js';
import UBIManifestShipmentList from './Pages/Manifest/ubiManifest.js';
import PrivateRoute from "./components/privateRoute.js"; 

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/usr/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/usr/us-rate-inquiry" element={<PrivateRoute><USRateInquiry /></PrivateRoute>} />
          <Route path="/usr/result" element={<PrivateRoute><USRateInquiryResult /></PrivateRoute>} />
          <Route path="/usr/orderList" element={<PrivateRoute><OrderList /></PrivateRoute>} />
          <Route path="/usr/orderDetail/:orderId" element={<PrivateRoute><OrderDetail /></PrivateRoute>} />
          <Route path="/usr/shipmentList" element={<PrivateRoute><ShipmentList /></PrivateRoute>} />
          <Route path="/usr/shipmentDetail/:shipmentId" element={<PrivateRoute><ShipmentDetail /></PrivateRoute>} />
          <Route path="/usr/warehouseList" element={<PrivateRoute><WarehouseList /></PrivateRoute>} />
          <Route path="/usr/warehouseProductList" element={<PrivateRoute><WarehouseProductList /></PrivateRoute>} />
          <Route path="/usr/warehouseProductDetail/:productId" element={<PrivateRoute><WarehouseProductDetail /></PrivateRoute>} />
          <Route path="/usr/clientList" element={<PrivateRoute><ClientList /></PrivateRoute>} />
          <Route path="/usr/clientDetail/:clientId" element={<PrivateRoute><ClientDetail /></PrivateRoute>} />
          <Route path="/usr/ubi-manifest" element={<PrivateRoute><UBIManifestShipmentList /></PrivateRoute>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
