import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card, Descriptions, List, Spin, Alert, Button, Table } from 'antd';
import { orderDetailRequest } from '../../features/order/orderActions';
import NavBar from '../../components/navBar';
import { ALLOCATION_PRIORITY_MAP } from '../../constants/shipheroConstants';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const { Item } = Descriptions;

const OrderDetail = () => {

    const { orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const orderDetail = useSelector(state => state.order.orderDetail);
    const loading = useSelector(state => state.order.loading);
    const error = useSelector(state => state.order.error);
    useDocumentTitle(orderDetail ? `[${orderDetail.orderNumber}] - Order Detail` : 'Order Detail');

    useEffect(() => {
        dispatch(orderDetailRequest(orderId));
    }, [dispatch, orderId]);

    const handleBackClick = () => {
        navigate('/usr/orderList');
    };


    if (loading) {
        return <Spin tip="Loading..." />;
    }
    if (error) {
        return <Alert message="Error" description={error} type="error" showIcon />;
    }
    if (!orderDetail) {
        return <Alert message="No order details available" type="warning" showIcon />;
    }

    const lineItemColumns = [
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
        { title: 'Fulfillment Status', dataIndex: 'fulfillmentStatus', key: 'fulfillmentStatus' },
        { title: 'Ordered Quantity', dataIndex: 'quantity', key: 'quantity' },
        { title: 'Shipped Quantity', dataIndex: 'quantityShipped', key: 'quantityShipped' },
        { title: 'Allocated Quantity', dataIndex: 'quantityAllocated', key: 'quantityAllocated' },
        { title: 'Item Price', dataIndex: 'price', key: 'price' },
        { title: 'Total Price', dataIndex: 'subtotal', key: 'subtotal' },
        { title: 'Customs Value', dataIndex: 'customsValue', key: 'customsValue' },
    ];

    const shipmentColumns = [
        { title: 'Profile', dataIndex: 'profile', key: 'profile' },
        { title: 'Ship Date', dataIndex: 'createdDate', key: 'createdDate' },
        { title: 'Tracking Number', dataIndex: 'trackingNumber', key: 'trackingNumber' },
        {
            title: 'Tracking URL',
            key: 'trackingUrl',
            render: (text, record) => (
                <a href={record.trackingUrl} target="_blank" rel="noopener noreferrer">Track</a>
            )
        },
        { title: 'Carrier', dataIndex: 'carrier', key: 'carrier' },
        { title: 'Shipping Name', dataIndex: 'shippingName', key: 'shippingName' },
        { title: 'Shipping Method', dataIndex: 'shippingMethod', key: 'shippingMethod' },
        { title: 'Country', dataIndex: 'destCountry', key: 'destCountry' },
        { title: 'Zip', dataIndex: 'destZip', key: 'destZip' },
        { title: 'State', dataIndex: 'destState', key: 'destState' },
        { title: 'Receiver Name', dataIndex: 'receiverName', key: 'receiverName' },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Link to={`/usr/shipmentDetail/${record.id}`}>Detail</Link>
            ),
        },
    ];

    return (
        <div>
            <NavBar />

            <div style={{ padding: 20 }}>
                <Card title="Order Detail" bordered={false}>
                    <Descriptions bordered>
                        <Item label="Order ID">
                            {
                                <a href={`https://app.shiphero.com/dashboard/orders/details/${orderDetail.orderId}`} target="_blank" rel="noopener noreferrer">
                                    {orderDetail.orderId}
                                </a>
                            }
                        </Item>
                        <Item label="Order Number">{orderDetail.orderNumber}</Item>
                        {/* <Item label="Parent Order ID">{orderDetail.partner_order_id}</Item> */}
                        <Item label="Fulfillment Status">{orderDetail.fulfillmentStatus}</Item>
                        <Item label="Order Date">{orderDetail.orderDate}</Item>
                        <Item label="Required Ship Date">{orderDetail.requiredShipDate}</Item>
                        <Item label="Shop Name">{orderDetail.shopName}</Item>
                        <Item label="Profile">{orderDetail.profile}</Item>
                        <Item label="Email">{orderDetail.email}</Item>
                        <Item label="Priority Flag">{orderDetail.priorityFlag ? 'Yes' : 'No'}</Item>
                        <Item label="Allocation Priority">{(orderDetail.allocationPriority in ALLOCATION_PRIORITY_MAP) ? ALLOCATION_PRIORITY_MAP[orderDetail.allocationPriority] : "Unknow"}</Item>

                        {/* TODO Arthur:To display client name */}
                        {/* <Item label="Account ID">{orderDetail.account_id}</Item> */}
                        <Item label="Tags">{orderDetail.tags.join(', ')}</Item>
                        <Item label="Last Sync Time">{orderDetail.lastSyncTime}</Item>
                    </Descriptions>
                </Card>

                <Card title="Shipping Details" bordered={false} style={{ marginTop: '24px' }}>
                    <Descriptions bordered>
                        <Item label="Carrier">{orderDetail.shippingLines.carrier}</Item>
                        <Item label="Shipping Name">{orderDetail.shippingLines.title}</Item>
                        <Item label="Shipping Method">{orderDetail.shippingLines.method}</Item>
                    </Descriptions>
                </Card>

                <Card title="Shipping Address" bordered={false} style={{ marginTop: '24px' }}>
                    <Descriptions bordered>
                        <Item label="Name">{orderDetail.shippingAddress.fullName}</Item>
                        <Item label="Address">
                            {orderDetail.shippingAddress.address1}
                            {orderDetail.shippingAddress.address2 ? (
                                <>
                                    <br />
                                    {orderDetail.shippingAddress.address2}
                                </>
                            ) : null}
                        </Item>
                        {/* <Item label="Address1">{orderDetail.shippingAddress.address1}</Item>
                        <Item label="Address2">{orderDetail.shippingAddress.address2}</Item> */}
                        <Item label="City">{orderDetail.shippingAddress.city}</Item>
                        <Item label="State">{orderDetail.shippingAddress.state}</Item>
                        <Item label="Country">{orderDetail.shippingAddress.country}</Item>
                        <Item label="Zip">{orderDetail.shippingAddress.zip}</Item>
                    </Descriptions>
                </Card>

                <Card title="Line Items" bordered={false} style={{ marginTop: '24px' }}>
                    <Table
                        columns={lineItemColumns}
                        dataSource={orderDetail.lineItems}
                        loading={loading}
                        rowKey="id"
                        pagination={false}
                    />
                </Card>

                <Card title="Shipments" bordered={false} style={{ marginTop: '24px' }}>
                    <Table
                        columns={shipmentColumns}
                        dataSource={orderDetail.shipments}
                        loading={loading}
                        rowKey="id"
                        pagination={false}
                    />
                </Card>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <div>
                        <Button type="primary" onClick={handleBackClick}>
                            Back to list
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;
