import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Descriptions, Spin, Alert, Button, Table } from 'antd';
import { fetchWarehouseProductDetail } from '../../features/warehouseProduct/warehouseProductSlice';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import NavBar from '../../components/navBar';

const { Item } = Descriptions;

const WarehouseProductDetail = () => {

    const { productId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const productDetail = useSelector(state => state.warehouseProduct.productDetail);
    const loading = useSelector(state => state.warehouseProduct.loading);
    const error = useSelector(state => state.warehouseProduct.error);
    useDocumentTitle(productDetail ? `[${productDetail.sku}] - Product Detail` : 'Product Detail');

    useEffect(() => {
        dispatch(fetchWarehouseProductDetail(productId));
    }, [dispatch, productId]);

    const handleBackClick = () => {
        navigate('/usr/warehouseProductList');
    };

    if (loading) {
        return <Spin tip="Loading..." />;
    }
    if (error) {
        return <Alert message="Error" description={error} type="error" showIcon />
    }
    if (!productDetail) {
        return (
            <>
                <Alert message="No product details available" type="warning" showIcon />
            </>
        );
    }

    //Warehouse list
    const inboundColumn = [
        { title: 'ID', dataIndex: 'legacy_id', key: 'legacy_id' },
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        { title: 'Warehouse', dataIndex: 'warehouse_identifier', key: 'warehouse_identifier' },
        // {
        //     title: 'PO Number', key: 'po_id',
        //     render: (text, record) => {
        //         <span>Not Implement yet</span>
        //     }
        // },
        { title: 'PO Date', dataIndex: 'po_date', key: 'po_date' },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
        { title: 'Quantity Received', dataIndex: 'quantity_received', key: 'quantity_received' },
        { title: 'Quantity Rejected', dataIndex: 'quantity_rejected', key: 'quantity_rejected' },
        { title: 'State', dataIndex: 'status', key: 'status' },
    ];

    return (
        <div>
            <NavBar />

            <div style={{ padding: '20px' }}>

                <Card title="Product Detail" bordered={false}>
                    <Descriptions bordered>
                        <Item label="Client Name">{productDetail.client_name}</Item>
                        <Item label="Product ID">
                            {
                                <a href={`https://app.shiphero.com/dashboard/products/details/${productDetail.legacy_id}`} target="_blank" rel="noopener noreferrer">{productDetail.legacy_id}</a>
                            }
                        </Item>
                        <Item label="Product Name">{productDetail.product?.name}</Item>
                        <Item label="SKU">{productDetail.sku}</Item>
                        <Item label="Price">{productDetail.price}</Item>
                        <Item label="Value">{productDetail.value}</Item>
                        <Item label="Value Currency">{productDetail.value_currency}</Item>
                        <Item label="On Hand">{productDetail.on_hand}</Item>
                        <Item label="Allocated">{productDetail.allocated}</Item>
                        <Item label="Available">{productDetail.available}</Item>
                        <Item label="Customs Value">{productDetail.customs_value}</Item>
                        <Item label="Created At">{productDetail.created_at}</Item>
                        <Item label="Updated At">{productDetail.updated_at}</Item>
                        <Item label="Tags">{productDetail.product?.tags.join(', ')}</Item>

                    </Descriptions>
                </Card>

                <Card title="Inbounds" bordered={false} style={{ marginTop: '24px' }}>
                    <Table
                        columns={inboundColumn}
                        dataSource={productDetail.inbounds}
                        loading={loading}
                        rowKey="legacy_id"
                        pagination={false}
                    />
                </Card>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <div>
                        <Button type="primary" onClick={handleBackClick}>Back to list</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default WarehouseProductDetail;
