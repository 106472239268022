import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
  manifestShipmentListRequest,
  manifestShipmentListSuccess,
  manifestShipmentListFailure,
  manifest,
  manifestSuccess,
  manifestFailure,
  ubiCancelShipment,
  ubiCancelShipmentSuccess,
  ubiCancelShipmentFailure,
} from "./ubiManifestSlice";
import { notify } from "../../utils/notifications";

function* queryManifestShipment(action) {
  try {
    let requestBody = { ...action.payload };
    // Adjust page index for 0-based indexing if necessary
    if (requestBody && requestBody.pageIndex >= 1) {
      requestBody.pageIndex -= 1;
    }
    // API call
    const response = yield call(
      apiClient.post,
      "/ubi/manifestShipmentList",
      requestBody
    );
    const responseBody = response.data;
    const { status, message, result } = responseBody;

    // Check if API response indicates failure
    if (status === "fail") {
      throw new Error(
        message ||
          "Unknown error occurred in the fetching manifest shipment list."
      );
    }

    // Adjust result data if needed and dispatch success action
    result.pageIndex += 1;
    yield put(manifestShipmentListSuccess(result));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }

    // Handle other errors
    yield put(manifestShipmentListFailure(error.message));
  }
}

function* manifestShipment(action) {
  try {
    const { shipmentList } = action.payload;
    const requestBody = {
      trackingNumbers: shipmentList.map((shipment) => shipment.trackingNumber),
    };

    const response = yield call(
      apiClient.post,
      "/ubi/manifestShipment",
      requestBody
    );
    const responseBody = response.data;
    const { status, message } = responseBody;

    if (status === "fail") {
      notify("error", `Manifest Failed due to: ${message}`, 5);
      throw new Error(message);
    }

    yield put(manifestSuccess());
    notify("success", "Shipments Manifested Successfully", 5);

    // Wait for the manifest to complete before fetching the shipment list again
    const filters = yield select((state) => state.ubiManifest.filters);
    yield put(manifestShipmentListRequest(filters));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(manifestFailure(error.message));
  }
}

function* ubiCancelShipmentRequest(action) {
  try {
    const { trackingNumber } = action.payload;

    const requestBody = {
      tracking_number: trackingNumber,
    };

    const response = yield call(
      apiClient.post,
      "/ubi/voidUbiShipment",
      requestBody
    );
    const responseBody = response.data;
    const { status, message } = responseBody;

    if (status === "fail") {
      notify("error", `${message}`, 5);
      throw new Error(message);
    }

    yield put(ubiCancelShipmentSuccess());
    const filters = yield select((state) => state.ubiManifest.filters);
    yield put(manifestShipmentListRequest(filters));
    notify("success", `${message}`, 5);
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(ubiCancelShipmentFailure(error.message));
  }
}

export default function* ubiManifestSaga() {
  yield takeLatest(manifestShipmentListRequest.type, queryManifestShipment);
  yield takeLatest(manifest.type, manifestShipment);
  yield takeLatest(ubiCancelShipment.type, ubiCancelShipmentRequest);
}
