import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {},
    warehouseList: [],
    loading: false,
    error: null,
};

const warehouseSlice = createSlice({
    name: "warehouse",
    initialState,
    reducers: {
        fetchWarehouseList: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchWarehouseListSuccess: (state, action) => {
            state.loading = false;
            state.warehouseList = action.payload;
        },
        fetchWarehouseListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchWarehouseList,
    fetchWarehouseListSuccess,
    fetchWarehouseListFailure,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
