import React, { useState } from "react";
import { Menu, Dropdown, Space, Drawer, Button } from "antd";
import { Link } from "react-router-dom";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import ProfileMenu from "./ProfileMenu";
import styled from "styled-components";
import logo from "../assets/logo.png";

// Updated menu items using the new Ant Design format
const rateInquiryMenuItems = [
  {
    key: "us-rate-inquiry",
    label: <Link to="/usr/us-rate-inquiry">US Rate Inquiry</Link>,
  },
];

const usersMenuItems = [
  {
    key: "all-users",
    label: <Link to="#">All Users</Link>,
  },
  {
    key: "create-user",
    label: <Link to="#">Create User</Link>,
  },
];

const clientsMenuItems = [
  {
    key: "client-list",
    label: <Link to="/usr/clientList">Client List</Link>,
  },
];

const warehouseMenuItems = [
  {
    key: "warehouses",
    label: <Link to="/usr/warehouseList">Warehouses</Link>,
  },
  {
    key: "warehouse-products",
    label: <Link to="/usr/warehouseProductList">Warehouse Products</Link>,
  },
];

const listPageMenuItems = [
  {
    key: "orders",
    label: <Link to="/usr/orderList">Orders</Link>,
  },
  {
    key: "shipments",
    label: <Link to="/usr/shipmentList">Shipments</Link>,
  },
];

const shipmentMenuItems = [
  {
    key: "ubi-manifest",
    label: <Link to="/usr/ubi-manifest">UBI Manifest</Link>,
  },
];

const NavBar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <StyledNavBar>
      <div className="left">
        <Button type="text" onClick={showDrawer} className="menuButton">
          <MenuOutlined />
        </Button>
        <Drawer
          title="Navigation"
          placement="left"
          closable
          onClose={closeDrawer}
          open={drawerVisible}
        >
          <div className="drawerContent">
            <Link
              to="/usr/dashboard"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Dashboard</span>
            </Link>
            <br />
            <Link
              to="/usr/us-rate-inquiry"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>US Rate Inquiry</span>
            </Link>
            <br />
            <Link to="#" className="drawerLink" onClick={closeDrawer}>

              <span style={{ verticalAlign: "middle" }}>All Users</span>
            </Link>
            <br />
            <Link to="#" className="drawerLink" onClick={closeDrawer}>
              <span style={{ verticalAlign: "middle" }}>Create User</span>
            </Link>
            <br />
            <Link
              to="/usr/clientList"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Client List</span>
            </Link>
            <br />
            <Link
              to="/usr/orderList"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Orders</span>
            </Link>
            <br />
            <Link
              to="/usr/shipmentList"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Shipments</span>
            </Link>
            <br />
            <Link
              to="/usr/ubi-manifest"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>UBI Manifest</span>
            </Link>
            <br />
            <Link
              to="/usr/warehouseList"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>Warehouses</span>
            </Link>
            <br />
            <Link
              to="/usr/warehouseProductList"
              className="drawerLink"
              onClick={closeDrawer}
            >
              <span style={{ verticalAlign: "middle" }}>
                Warehouse Products
              </span>
            </Link>
          </div>
        </Drawer>
      </div>
      <div className="logo-space">
        <Space>
          <img
            src={logo}
            alt="logo"
            style={{ width: "40px", padding: "20px" }}
          />
        </Space>
      </div>
      <div className="center">
        <a href="/usr/dashboard" className="dropdown">
          <Space>
            <span style={{ verticalAlign: "middle" }}>Dashboard</span>
          </Space>
        </a>
        <Dropdown
          menu={{ items: rateInquiryMenuItems }}
          trigger={["click", "hover"]}
        >
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Rate Inquiry</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown menu={{ items: usersMenuItems }} trigger={["click", "hover"]}>
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Users</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{ items: clientsMenuItems }}
          trigger={["click", "hover"]}
        >
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Clients</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{ items: warehouseMenuItems }}
          trigger={["click", "hover"]}
        >
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Warehouse</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>

        <Dropdown
          menu={{ items: listPageMenuItems }}
          trigger={["click", "hover"]}
        >
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Lists</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
        <Dropdown
          menu={{ items: shipmentMenuItems }}
          trigger={["click", "hover"]}
        >
          <a href="/" onClick={(e) => e.preventDefault()} className="dropdown">
            <Space>
              <span style={{ verticalAlign: "middle" }}>Shipments</span>
              <DownOutlined className="dropdownicon" />
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className="right">
        <ProfileMenu />
      </div>
    </StyledNavBar>
  );
};

const StyledNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #f8f8f8;
  color: black;
  height: 90px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  font-size: 1.1em;
  font-weight: 500;
  position: relative;
  z-index: 10; /* Ensure navbar is above other content */

  .left {
    display: none;
    flex: 1;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
    flex: 3;
    gap: 30px;
  }

  .right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .dropdown {
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
  }

  .dropdownicon {
    font-size: 0.6em;
  }

  .icon {
    color: #3bc3b3;
  }

  .link {
    margin: 0 30px;
    white-space: nowrap;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
  }

  .line {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #3bc3b3, #e6e628, #3bc3b3);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  .menuButton {
    font-size: 1.2em;
    color: black;
    align-items: left;
    display: flex;
  }

  .drawerContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0; /* Add padding to top and bottom */
  }

  .drawerLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    padding: 10px 0;
    gap: 10px; /* Add gap between icon and text */
  }

  .drawerIcon {
    margin-right: 10px; /* Add margin to the right of icons */
  }

  @media (max-width: 768px) {
    .center {
      display: none;
    }
    .left {
      display: flex;
    }
    ,
    .logo-space {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .center {
      display: flex;
    }
    .left {
      display: none;
    }
  }
`;

export default NavBar;
