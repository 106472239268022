// src/Pages/OrderList/orderList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouseProductList } from '../../features/warehouseProduct/warehouseProductSlice';
import { fetchCustomerList, fetchWarehouseList } from '../../features/metadata/metadataActions';
import { Table, Button, Input, Select, Row, Col } from 'antd';
import NavBar from '../../components/navBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const { Option } = Select;

const WarehouseProductList = () => {
  useDocumentTitle('Warehouse Product List - ODIN');
  const dispatch = useDispatch();
  const { productList, loading } = useSelector((state) => state.warehouseProduct);
  const { customerList, warehouseList } = useSelector((state) => state.metadata);

  const [filters, setFilters] = useState({
    clientId: '',
    warehouseIdentifier: '',
    warehouseName: '',
    productName: '',
    sku: '',
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    //Init Query
    //Metadata query
    dispatch(fetchWarehouseList());
    dispatch(fetchCustomerList());
    //Default first page query
    dispatch(fetchWarehouseProductList({}));
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    dispatch(fetchWarehouseProductList(filters));
  };

  const columns = [
    { title: 'Client Name', dataIndex: 'clientName', key: 'clientName' },
    { title: 'Warehouse', dataIndex: 'warehouseName', key: 'warehouseName' },
    { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
    { title: 'SKU', dataIndex: 'sku', key: 'sku' },
    { title: 'On Hand', dataIndex: 'onHand', key: 'onHand' },
    { title: 'Available', dataIndex: 'available', key: 'available' },
    { title: 'Allocated', dataIndex: 'allocated', key: 'allocated' },
    { title: 'Customs Value', dataIndex: 'customsValue', key: 'customsValue' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Link to={`/usr/warehouseProductDetail/${record.legacyId}`}>Detail</Link>
        </>
      ),
    },
  ];

  return (
    <div>
      <NavBar />
      <div style={{ padding: 20 }}>
        <Row gutter={16}>
          <Col span={4}>
            <Select
              allowClear
              showSearch
              placeholder="Client Name"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => handleSelectChange('clientId', value)}
              style={{ width: '100%' }}
            >
              {customerList.map(customerItem => (
                <Option key={customerItem.id} value={customerItem.id}>{customerItem.name}</Option>
              ))}
            </Select>
          </Col>

          <Col span={4}>
            <Select
              placeholder="Warehouse"
              onChange={(value) => handleSelectChange('warehouseIdentifier', value)}
              style={{ width: '100%' }}
              allowClear
            >
              {warehouseList.map(warehouseItem => (
                <Option key={warehouseItem.id} value={warehouseItem.identifier}>{warehouseItem.displayName}</Option>
              ))}
            </Select>
          </Col>

          <Col span={3}>
            <Input placeholder="Product Name" name="productName" value={filters.productName} onChange={handleInputChange} />
          </Col>
          <Col span={3}>
            <Input placeholder="SKU" name="sku" value={filters.sku} onChange={handleInputChange} />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={handleSearch}>Search</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={productList.data}
          loading={loading}
          rowKey="id"
          bordered
          pagination={{
            current: productList.pageIndex,
            pageSize: productList.pageSize,
            total: productList.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: (pageIndex, pageSize) => {
              dispatch(fetchWarehouseProductList({ ...filters, pageIndex, pageSize }));
            },
          }}
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default WarehouseProductList;
