// src/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loading: false,
  error: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signinRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    signinSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.token = action.payload.access_token;
    },
    signinError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { signinRequest, signinSuccess, signinError } = authSlice.actions;

export default authSlice.reducer;
