import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientList: [],
    clientDetail: null,
    loading: false,
    error: null,
};

const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        /*********List************/
        getClientList: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getClientListSuccess: (state, action) => {
            state.loading = false;
            state.clientList = action.payload;
        },
        getClientListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /**********Detail****************/
        getClientDetail: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getClientDetailSuccess: (state, action) => {
            state.loading = false;
            state.clientDetail = action.payload;
        },
        getClientDetailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getClientList,
    getClientListSuccess,
    getClientListFailure,
    getClientDetail,
    getClientDetailSuccess,
    getClientDetailFailure,
} = clientSlice.actions;

export default clientSlice.reducer;

