// src/Pages/OrderList/orderList.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouseList } from '../../features/warehouse/warehouseActions';
import { Table, message } from 'antd';
import NavBar from '../../components/navBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';


const WarehouseList = () => {
  useDocumentTitle('Warehouse List - ODIN');
  const dispatch = useDispatch();
  const { warehouseList, loading } = useSelector((state) => state.warehouse);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    //Init Query
    //Default first page query
    dispatch(fetchWarehouseList({}));
  }, [dispatch]);


  const columns = [
    { title: 'Identifier', dataIndex: 'identifier', key: 'identifier' },
    { title: 'Profile', dataIndex: 'profile', key: 'profile' },
    { title: 'From Name', dataIndex: 'fromName', key: 'fromName' },
    { title: 'Address1', dataIndex: 'address1', key: 'address1' },
    { title: 'Address2', dataIndex: 'address2', key: 'address2' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Zip', dataIndex: 'zip', key: 'zip' },
    { title: 'Country', dataIndex: 'country', key: 'country' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          {/* <Link onClick={() => setShowAlert(true)}>Detail</Link> */}
          <Link onClick={() => messageApi.info('Not Implement yet')}>Detail</Link>
        </>
      ),
    },
  ];

  return (
    <div>
      <NavBar />
      {contextHolder}
      <div style={{ padding: 20 }}>
        <Table
          columns={columns}
          dataSource={warehouseList}
          loading={loading}
          bordered
          rowKey="id"
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default WarehouseList;
