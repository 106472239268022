// src/features/rateInquiry/InputRow.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Grid, IconButton, Paper, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { updateRow } from '../../features/rateInquiry/rateInquiryActions';

const InputRow = ({ row, onDeleteRow }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateRow({ id: row.id, field: name, value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    dispatch(updateRow({ id: row.id, field: name, value: checked }));
  };

  return (
    <Paper elevation={3} style={styles.row}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={row.residential}
                onChange={handleCheckboxChange}
                name="residential"
                color="primary"
              />
            }
            label={<Typography variant="body2">Residential</Typography>}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Zip Code"
            variant="outlined"
            name="zipCode"
            value={row.zipCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <TextField
            fullWidth
            label="Length"
            variant="outlined"
            name="length"
            value={row.length}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <TextField
            fullWidth
            label="Width"
            variant="outlined"
            name="width"
            value={row.width}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <TextField
            fullWidth
            label="Height"
            variant="outlined"
            name="height"
            value={row.height}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1.5}>
          <TextField
            fullWidth
            label="Weight"
            variant="outlined"
            name="weight"
            value={row.weight}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={1} container justifyContent="flex-end">
          <IconButton color="secondary" onClick={() => onDeleteRow(row.id)} title="Delete Row">
            <DeleteIcon style={{ color: "#D34339" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = {
  row: {
    padding: '16px',
    marginBottom: '10px',
  },
};

export default InputRow;
