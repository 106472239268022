// src/Pages/OrderList/orderList.js
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getClientList } from '../../features/client/clientActions';
import { Table, Button, Input, Row, Col, message } from 'antd';
import NavBar from '../../components/navBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ClientList = () => {
  useDocumentTitle('Customer List - ODIN');
  const dispatch = useDispatch();
  const { clientList, loading } = useSelector((state) => state.client);
  const [messageApi, contextHolder] = message.useMessage();
  const [filters, setFilters] = useState({
    clientName: '',
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    //First page query
    dispatch(getClientList({}));
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    dispatch(getClientList(filters));
  };

  const columns = [
    { title: 'Client Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Username', dataIndex: 'username', key: 'username' },
    { title: 'Is3pl', dataIndex: 'is3pl', key: 'is3pl' },
    { title: 'Warehouse Count', dataIndex: 'warehouseCount', key: 'warehouseCount' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        // <Link to={`/usr/clientDetail/${record.legacyId}`}>Detail</Link>
        <Link onClick={() => messageApi.info('Not Implement yet')}>Detail</Link>
      )
    },
  ];

  return (
    <div>
      <NavBar />
      {contextHolder}
      <div style={{ padding: 20 }}>
        <Row gutter={16}>
          <Col span={4}>
            <Input placeholder="Client Name" name="clientName" value={filters.clientName} onChange={handleInputChange} />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={handleSearch}>Search</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={clientList.data}
          loading={loading}
          bordered
          rowKey="id"
          pagination={{
            current: clientList.pageIndex,
            pageSize: clientList.pageSize,
            total: clientList.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: (pageIndex, pageSize) => {
              dispatch(getClientList({ ...filters, pageIndex, pageSize }));
            },
          }}
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default ClientList;
