import { Typography } from "@mui/material";

const TooltipContent = (carrier, additionalNote) => {
  const amountDetails = carrier.dataList[0];
  const surchargeDetails = carrier.surchargeInfo.feeItems;
  return (
    <div>
      <Typography variant="body2">
        {carrier.totalAmount === "N/A"
          ? `${carrier.surchargeInfo.errorMsg}`
          : ""}
        <strong>
          {carrier.totalAmount === "N/A"
            ? ""
            : `${additionalNote ? additionalNote : ""}`}
        </strong>
      </Typography>
      <Typography variant="body2">
        <strong>{carrier.totalAmount === "N/A" ? "" : "Fee Breakdown"}</strong>
      </Typography>
      <Typography variant="body2">
        {carrier.totalAmount === "N/A"
          ? ""
          : `Shipping Fee: ${amountDetails.currency === "USD" ? "$" : ""}${
              amountDetails.amount
            }`}
      </Typography>
      {surchargeDetails.map((item, index) => (
        <Typography key={index} variant="body2">
          {item._name}: {amountDetails.currency === "USD" ? "$" : ""}
          {item._value}
        </Typography>
      ))}
    </div>
  );
};

export default TooltipContent;
