// src/Pages/OrderList/orderList.js
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { shipmentListRequest } from '../../features/shipment/shipmentActions';
import { shippingMethodListRequest, fetchCustomerList } from '../../features/metadata/metadataActions';
import { Table, Button, Input, Select, DatePicker, Row, Col, Space } from 'antd';
import NavBar from '../../components/navBar';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ShipmentList = () => {
  useDocumentTitle('Shipment List - ODIN');
  const location = useLocation();
  const dispatch = useDispatch();
  const { shipmentList, loading } = useSelector((state) => state.shipment);
  const { shippingMethodList, carrierList, customerList } = useSelector((state) => state.metadata);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [filteredShippingMethods, setFilteredShippingMethods] = useState([]);

  const [filters, setFilters] = useState({
    clientId: '',
    orderNumber: '',
    orderDateStart: null,
    orderDateEnd: null,
    shipDateStart: null,
    shipDateEnd: null,
    trackingNumber: '',
    carrier: '',
    shippingMethod: '',
    country: '',
    zip: '',
    state: '',
    receiverName: '',
    pageIndex: 1,
    pageSize: 10,
  });

  useEffect(() => {
    //Init Query
    //Metadata query
    dispatch(shippingMethodListRequest());
    dispatch(fetchCustomerList());
    //Default first page query
    dispatch(shipmentListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialFilters = {};
    for (let param of params.entries()) {
      initialFilters[param[0]] = param[1];
    }
    setFilters(initialFilters);
    if (Object.keys(initialFilters).length > 0) {
      dispatch(shipmentListRequest(initialFilters));
    }
  }, [location, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    dispatch(shipmentListRequest(filters));
  };

  const handleCarrierChange = (selectedCarrier) => {
    setSelectedCarrier(selectedCarrier);
    setFilters({ ...filters, "carrier": selectedCarrier, "shippingMethod": '' });
    const filteredMethods = shippingMethodList.filter(method => method.carrier === selectedCarrier);
    setFilteredShippingMethods(filteredMethods);
  }

  const handleOrderDateChange = (dateArray, dateStringArray) => {
    const [start, end] = dateStringArray;
    setFilters({ ...filters, orderDateStart: start, orderDateEnd: end });
  }

  const handleShipDateChange = (dateArray, dateStringArray) => {
    const [start, end] = dateStringArray;
    setFilters({ ...filters, shipDateStart: start, shipDateEnd: end });
  }

  const columns = [
    { title: 'Client Name', width: 200, fixed: 'left', dataIndex: 'clientName', key: 'clientName' },
    { title: 'Order Number', width: 140, fixed: 'left', dataIndex: 'orderNumber', key: 'orderNumber' },
    { title: 'Order Date', width: 120, dataIndex: 'orderDate', key: 'orderDate' },
    { title: 'Ship Date', width: 120, dataIndex: 'shipDate', key: 'shipDate' },
    { title: 'Profile', width: 80, dataIndex: 'profile', key: 'profile' },
    {
      title: 'Tracking Number',
      width: 300,
      key: 'trackingNumber',
      render: (text, record) => (
        record.trackingUrl
          ? <a href={`${record.trackingUrl}`} target="_blank" rel="noopener noreferrer">{record.trackingNumber}</a>
          : record.trackingNumber
      )
    },
    { title: 'Carrier', width: 80, dataIndex: 'carrier', key: 'carrier' },
    { title: 'Method', width: 300, dataIndex: 'shippingMethod', key: 'shippingMethod' },
    { title: 'Country', width: 90, dataIndex: 'destCountry', key: 'destCountry' },
    { title: 'Zip', width: 140, dataIndex: 'destZip', key: 'destZip' },
    { title: 'Receiver Name', width: 140, dataIndex: 'receiverName', key: 'receiverName' },
    { title: 'Weight', width: 100, dataIndex: 'weight', key: 'weight' },
    { title: 'Length', width: 100, dataIndex: 'length', key: 'length' },
    { title: 'Width', width: 100, dataIndex: 'width', key: 'width' },
    { title: 'Height', width: 100, dataIndex: 'height', key: 'height' },
    { title: 'Billable Weight', width: 100, dataIndex: 'billableWeight', key: 'billableWeight' },
    {
      title: 'Actions',
      width: 160,
      fixed: 'right',
      key: 'actions',
      render: (text, record) => (
        <Space size="small">
          <a href={`https://app.shiphero.com/dashboard/orders/details/${record.orderId}`} target="_blank" rel="noopener noreferrer">Open in SH</a>
          |
          <Link to={`/usr/shipmentDetail/${record.shipmentId}`}>Detail</Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <NavBar />

      <div style={{ padding: 20 }}>
        <Row gutter={16}>

          <Col span={4}>
            <Select
              allowClear
              showSearch
              placeholder="Client Name"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              onChange={(value) => handleSelectChange('clientId', value)}
              style={{ width: '100%' }}
            >
              {customerList.map(customerItem => (
                <Option key={customerItem.id} value={customerItem.id}>{customerItem.name}</Option>
              ))}
            </Select>

          </Col>

          <Col span={3}>
            <Input placeholder="Order Number" name="orderNumber" value={filters.orderNumber} onChange={handleInputChange} />
          </Col>
          {/* TODO Arthur:Has problem,needs to refactor first. */}
          {/* <Col span={5}>
            <RangePicker
              placeholder={['Order Date From', 'End (Till Now)']}
              allowEmpty={[false, true]}
              onChange={(date, dateString) => handleOrderDateChange(date, dateString)}
            />
          </Col>
          <Col span={5}>
            <RangePicker
              placeholder={['Ship Date From', 'End (Till Now)']}
              allowEmpty={[false, true]}
              onChange={(date, dateString) => handleShipDateChange(date, dateString)}
            />
          </Col> */}
        </Row>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={6}>
            <Input placeholder="Tracking Number" name="trackingNumber" value={filters.trackingNumber} onChange={handleInputChange} />
          </Col>

          <Col span={4}>
            <Select
              placeholder="Carrier Type"
              onChange={(value) => handleCarrierChange(value)}
              style={{ width: '100%' }}
              allowClear
            >
              {carrierList.map(carrierItem => (
                <Option key={carrierItem} value={carrierItem}>{carrierItem}</Option>
              ))}
            </Select>
          </Col>

          <Col span={4}>
            <Select
              placeholder="Shipping Method"
              value={filters.shippingMethod}
              onChange={(value) => handleSelectChange('shippingMethod', value)}
              disabled={!selectedCarrier}
              style={{ width: '100%' }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {
                filteredShippingMethods.map(methodItem => (
                  <Option key={methodItem.method} value={methodItem.method}>{methodItem.method}</Option>
                ))
              }
            </Select>

          </Col>

        </Row>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={2}>
            <Input placeholder="Country" name="country" value={filters.country} onChange={handleInputChange} />
          </Col>
          <Col span={2}>
            <Input placeholder="Zip" name="zip" value={filters.zip} onChange={handleInputChange} />
          </Col>
          <Col span={2}>
            <Input placeholder="State" name="state" value={filters.state} onChange={handleInputChange} />
          </Col>
          <Col span={3}>
            <Input placeholder="Receiver Name" name="receiverName" value={filters.receiverName} onChange={handleInputChange} />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={handleSearch}>Search</Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={shipmentList.data}
          loading={loading}
          bordered
          scroll={{
            x: 1500,
            y: 700,
          }}

          rowKey="shipmentId"
          pagination={{
            current: shipmentList.pageIndex,
            pageSize: shipmentList.pageSize,
            total: shipmentList.total,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: (pageIndex, pageSize) => {
              dispatch(shipmentListRequest({ ...filters, pageIndex, pageSize }));
            },
          }}
          style={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default ShipmentList;
