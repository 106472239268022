import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Avatar, Space } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const ProfileMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  // Define menu items using the new Ant Design format
  const profileMenuItems = [
    {
      key: "settings",
      label: (
        <span style={{ fontSize: "15px" }}>
          <SettingOutlined style={{ fontSize: "18px", color: "silver" }} />
          &nbsp;Settings
        </span>
      ),
    },
    {
      key: "logout",
      label: (
        <span style={{ fontSize: "15px" }} onClick={handleLogout}>
          <LogoutOutlined style={{ fontSize: "18px", color: "#D34339" }} />
          &nbsp;Logout
        </span>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items: profileMenuItems }} trigger={["click", "hover"]}>
      <a
        href="/"
        onClick={(e) => e.preventDefault()}
        style={styles.profileLink}
      >
        <Space>
          <Avatar
            style={{ width: "40px", height: "40px" }}
            icon={<UserOutlined style={{ fontSize: "25px" }} />}
          />
        </Space>
      </a>
    </Dropdown>
  );
};

const styles = {
  profileLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  avatar: {
    backgroundColor: "#87d068",
  },
};

export default ProfileMenu;
