import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {},
    productList: {
        data: [],
        pageIndex: 1,
        pageSize: 10,
        total: 0
    },
    productDetail: null,
    loading: false,
    error: null,
    token: null,
};

const warehouseProductSlice = createSlice({
    name: "warehouseProduct",
    initialState,
    reducers: {
        fetchWarehouseProductList: (state, action) => {
            state.loading = true;
            state.error = null;
            state.filter = action.payload;
        },
        fetchWarehouseProductListSuccess: (state, action) => {
            state.loading = false;
            state.productList = action.payload;
        },
        fetchWarehouseProductListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /***********warehouseProduct Detail************/
        fetchWarehouseProductDetail: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchWarehouseProductDetailSuccess: (state, action) => {
            state.loading = false;
            state.productDetail = action.payload;
        },
        fetchWarehouseProductDetailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchWarehouseProductList,
    fetchWarehouseProductListSuccess,
    fetchWarehouseProductListFailure,
    fetchWarehouseProductDetail,
    fetchWarehouseProductDetailSuccess,
    fetchWarehouseProductDetailFailure
} = warehouseProductSlice.actions;

export default warehouseProductSlice.reducer;
