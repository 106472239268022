import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carrierList: [],
    shippingMethodList: [],
    warehouseList: [],
    customerList: [],
    loading: false,
    error: null,
};

const metadataSlice = createSlice({
    name: "metadata",
    initialState,
    reducers: {
        /*********Shipping Method************/
        shippingMethodListRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        shippingMethodListSuccess: (state, action) => {
            state.loading = false;
            state.shippingMethodList = action.payload;
            /**
             * Note by Arthur:In DB, carrier info is saved with shipping method, we don't have plan for split them.
             * So we also set carrierList here.
             */
            state.carrierList = [];
            if (action.payload) {
                let uniqueCarrierMap = {};
                action.payload.forEach(item => {
                    if (!(item.carrier in uniqueCarrierMap)) uniqueCarrierMap[item.carrier] = true;
                });
                for (const carrier in uniqueCarrierMap) {
                    state.carrierList.push(carrier);
                }
            }
        },
        shippingMethodListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /**********Client List****************/
        fetchCustomerList: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchCustomerListSuccess: (state, action) => {
            state.loading = false;
            state.customerList = action.payload;
        },
        fetchCustomerListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /**********Warehouse List****************/
        fetchWarehouseList: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        fetchWarehouseListSuccess: (state, action) => {
            state.loading = false;
            state.warehouseList = action.payload;
        },
        fetchWarehouseListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    shippingMethodListRequest,
    shippingMethodListSuccess,
    shippingMethodListFailure,
    fetchCustomerList,
    fetchCustomerListSuccess,
    fetchCustomerListFailure,
    fetchWarehouseList,
    fetchWarehouseListSuccess,
    fetchWarehouseListFailure,
} = metadataSlice.actions;

export default metadataSlice.reducer;

