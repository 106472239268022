import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {},
    orderList: {
        data: [],
        pageIndex: 1,
        pageSize: 10,
        total: 0
    },
    orderDetail: null,
    loading: false,
    error: null,
};

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        orderListRequest: (state, action) => {
            state.loading = true;
            state.error = null;
            state.filter = action.payload;
        },
        orderListSuccess: (state, action) => {
            state.loading = false;
            state.orderList = action.payload;
        },
        orderListFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        /***********Order Detail************/
        orderDetailRequest: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        orderDetailSuccess: (state, action) => {
            state.loading = false;
            state.orderDetail = action.payload;
        },
        orderDetailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

    },
});

export const {
    orderListRequest,
    orderListSuccess,
    orderListFailure,
    orderDetailRequest,
    orderDetailSuccess,
    orderDetailFailure,
} = orderSlice.actions;

export default orderSlice.reducer;

