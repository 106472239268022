import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rows: [{ id: 1, zipCode: "", length: "", width: "", height: "", weight: "", residential: true }],
  loading: false,
  units: { dimension: "in", weight: "oz" },
  result: [],
};

const rateInquirySlice = createSlice({
  name: "rateInquiry",
  initialState,
  reducers: {
    addRow: (state) => {
      const newRow = {
        id:
          state.rows.length > 0 ? state.rows[state.rows.length - 1].id + 1 : 1,
        zipCode: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        residential: true,
      };
      state.rows.push(newRow);
    },
    updateRow: (state, action) => {
      const { id, field, value } = action.payload;
      const row = state.rows.find((row) => row.id === id);
      if (row) {
        row[field] = value;
      }
    },
    removeRow: (state, action) => {
      state.rows = state.rows.filter((row) => row.id !== action.payload);
    },
    updateUnits: (state, action) => {
      const { field, value } = action.payload;
      state.units[field] = value;
    },
    resetRows: (state, action) => {
      state.rows = [{ id: 1, zipCode: "", length: "", width: "", height: "", weight: "", residential: true }];
      state.loading = false;
      state.units = { dimension: "in", weight: "oz" };
      state.result = [];
    },
    setResultRequest: (state, action) => {
      state.loading = true;
      state.result = action.payload;
    },
    updateResult: (state, action) => {
      state.result = action.payload;
      state.loading = false;
    },
  },
});

export const {
  addRow,
  updateRow,
  removeRow,
  updateUnits,
  resetRows,
  setResultRequest,
  updateResult,
} = rateInquirySlice.actions;

export default rateInquirySlice.reducer;
