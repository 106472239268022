import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputRow from "./InputRow";
import {
  addRow,
  removeRow,
  updateUnits,
  setResultRequest,
} from "../../features/rateInquiry/rateInquiryActions";
import {
  Button,
  Container,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NavBar from "../../components/navBar";

const RateInquiry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rows} = useSelector((state) => state.rateInquiry);
  const weightUnit = useSelector((state) => state.rateInquiry.units.weight);
  const dimensionUnit = useSelector(
    (state) => state.rateInquiry.units.dimension
  );

  const handleAddRow = () => {
    dispatch(addRow());
  };

  const handleDeleteRow = (id) => {
    dispatch(removeRow(id));
  };

  const handleUnitUpdate = (e) => {
    const { name, value } = e.target;
    dispatch(updateUnits({ field: name, value: value }));
  };

  const handleSubmit = () => {
    dispatch(setResultRequest({ state: { rows, weightUnit, dimensionUnit } }));

    navigate("/usr/result", { state: { rows, weightUnit, dimensionUnit } });
  };

  return (
    <div>
      <NavBar />
      <div style={styles.container}>
        <Container style={styles.content}>
          <Typography variant="h4" gutterBottom style={styles.title}>
            Rate Inquiry Tool
          </Typography>
          <Grid container spacing={2} style={styles.unitsContainer}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="weight-unit-label">Weight Unit</InputLabel>
                <Select
                  labelId="weight-unit-label"
                  name="weight"
                  value={weightUnit}
                  onChange={handleUnitUpdate}
                  label="Weight Unit"
                >
                  <MenuItem value="kg">kg</MenuItem>
                  <MenuItem value="oz">oz</MenuItem>
                  <MenuItem value="lb">lb</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="dimension-unit-label">
                  Dimension Unit
                </InputLabel>
                <Select
                  labelId="dimension-unit-label"
                  name="dimension"
                  value={dimensionUnit}
                  onChange={handleUnitUpdate}
                  label="Dimension Unit"
                >
                  <MenuItem value="in">in</MenuItem>
                  <MenuItem value="cm">cm</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {rows.map((row) => (
            <InputRow
              key={row.id}
              row={row}
              onAddRow={handleAddRow}
              onDeleteRow={handleDeleteRow}
            />
          ))}
          <Grid
            container
            justifyContent={rows.length === 0 ? "center" : "flex-end"}
            spacing={2}
            style={styles.buttonContainer}
          >
            <Grid item>
              <Button
                title="Add New Row"
                variant="contained"
                color="success"
                size="medium"
                onClick={handleAddRow}
                startIcon={<AddIcon />}
                style={styles.addButton}
              >
                Add New Row
              </Button>
            </Grid>
            {rows.length > 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleSubmit}
                  title="Submit"
                  style={styles.submitButton}
                >
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#f4f6f8",
  },
  content: {
    flex: 1,
    padding: "20px",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  unitsContainer: {
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
  buttonContainer: {
    marginTop: "20px",
  },
  addButton: {
    marginRight: "10px",
  },
  submitButton: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
};

export default RateInquiry;
