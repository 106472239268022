import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
  orderListRequest,
  orderListSuccess,
  orderListFailure,
  orderDetailRequest,
  orderDetailSuccess,
  orderDetailFailure,
} from "./orderSlice";

function* queryOrder(action) {
  try {
    //Note by Arthur:Antd use 1-based index, but our backend use 0-based index.
    let requestBody = { ...action.payload };
    if (requestBody && requestBody.pageIndex >= 1) {
      requestBody.pageIndex -= 1;
    }
    const response = yield call(apiClient.post, "/order/list", requestBody);
    //Mock
    //const response = { data: mockOrderList };
    const responseBody = response.data;
    const { status, message, result } = responseBody;
    if (status === "fail") throw new Error(message);
    result.pageIndex += 1;
    yield put(orderListSuccess(result));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(orderListFailure(error.message));
  }
}

function* fetchOrderDetail(action) {
  try {
    const response = yield call(
      apiClient.get,
      `/order/detail/${action.payload}`
    );
    const responseBody = response.data;
    const { status, message, result } = responseBody;
    if (status === "fail") throw new Error(message);
    yield put(orderDetailSuccess(result));
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(orderDetailFailure(error.message));
  }
}

function* watchQueryOrder() {
  yield takeLatest(orderListRequest.type, queryOrder);
  yield takeLatest(orderDetailRequest.type, fetchOrderDetail);
}

export default watchQueryOrder;
