// src/app/rootReducer.js
import { combineReducers } from 'redux';
import authReducer from '../features/auth/authSlice';
import rateInquiryReducer from '../features/rateInquiry/rateInquirySlice';
// import other reducers here
import orderReducer from '../features/order/orderSlice';
import shipmentReducer from '../features/shipment/shipmentSlice';
import ubiManifestReducer from '../features/ubiManifest/ubiManifestSlice';
import metadataReducer from '../features/metadata/metadataSlice';
import warehouseReducer from '../features/warehouse/warehouseSlice';
import warehouseProductReducer from '../features/warehouseProduct/warehouseProductSlice';
import clientReducer from '../features/client/clientSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  rateInquiry: rateInquiryReducer,
  order: orderReducer,
  shipment: shipmentReducer,
  ubiManifest: ubiManifestReducer,
  metadata: metadataReducer,
  warehouse: warehouseReducer,
  warehouseProduct: warehouseProductReducer,
  client: clientReducer,
  // other reducers
});

export default rootReducer;
