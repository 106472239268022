import { call, put, takeLatest } from "redux-saga/effects";
import { apiClient } from "../../api";
import {
  shippingMethodListRequest,
  shippingMethodListSuccess,
  shippingMethodListFailure,
  fetchCustomerList,
  fetchCustomerListSuccess,
  fetchCustomerListFailure,
  fetchWarehouseList,
  fetchWarehouseListSuccess,
  fetchWarehouseListFailure,
} from "./metadataSlice";

/**
 * Note by Arthur 2024-07-25:Desc
 * For now, we will check local storage first, if not found, we will fetch data from server.
 * But, there is a known issue that metadata will be updated in server side, it's just not that rush.
 * So, in the futurue, we needs to add a metadata version info both local and server sides,
 * Once we found local version is outdated, we will fetch data from server.
 */

/**
 *
 * @param {*} type
 * @returns
 */
const getMetadataFromLocalStorage = (type) => {
  const metadata = localStorage.getItem(type);
  if (metadata) {
    return JSON.parse(metadata);
  }
  return null;
};

const setMetadataToLocalStorage = (type, data) => {
  localStorage.setItem(type, JSON.stringify(data));
};

function* loadShippingMethodList(action) {
  try {
    //Try to get data from local storage
    const shippingMethodList =
      getMetadataFromLocalStorage("shippingMethodList");
    if (shippingMethodList)
      yield put(shippingMethodListSuccess(shippingMethodList));
    else {
      const response = yield call(
        apiClient.get,
        "/metadata/shipping_method",
        {}
      );
      const responseBody = response.data;
      const { status, message, result } = responseBody;
      if (status === "fail") throw new Error(message);
      //Update local storage
      setMetadataToLocalStorage("shippingMethodList", result);
      yield put(shippingMethodListSuccess(result));
    }
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(shippingMethodListFailure(error.message));
  }
}

function* requestCustomerList(action) {
  try {
    //Try to get data from local storage
    const customerList = getMetadataFromLocalStorage("customerList");
    if (customerList) yield put(fetchCustomerListSuccess(customerList));
    else {
      const response = yield call(apiClient.get, "/metadata/customer", {});
      const responseBody = response.data;
      const { status, message, result } = responseBody;
      if (status === "fail") throw new Error(message);
      //Update local storage
      setMetadataToLocalStorage("customerList", result);
      yield put(fetchCustomerListSuccess(result));
    }
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(fetchCustomerListFailure(error.message));
  }
}

function* requestWarehouseList(action) {
  try {
    //Try to get data from local storage
    const warehouseList = getMetadataFromLocalStorage("warehouseList");
    if (warehouseList) yield put(fetchWarehouseListSuccess(warehouseList));
    else {
      const response = yield call(apiClient.get, "/metadata/warehouse", {});
      const responseBody = response.data;
      const { status, message, result } = responseBody;
      if (status === "fail") throw new Error(message);
      //Update local storage
      setMetadataToLocalStorage("warehouseList", result);
      yield put(fetchWarehouseListSuccess(result));
    }
  } catch (error) {
    // Check for 401 Unauthorized error and handle it
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      return; // Exit saga after handling unauthorized access
    }
    yield put(fetchWarehouseListFailure(error.message));
  }
}

function* watchMetadata() {
  yield takeLatest(shippingMethodListRequest.type, loadShippingMethodList);
  yield takeLatest(fetchCustomerList.type, requestCustomerList);
  yield takeLatest(fetchWarehouseList.type, requestWarehouseList);
}

export default watchMetadata;
